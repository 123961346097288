<script setup lang="ts">
const { sharedBoard, isShareOpen } = useBoardShare();

const title = computed(() => "Udostępnij tablicę");

const runtimeConfig = useRuntimeConfig();
const localePath = useLocalePath();

const link = computed(() => {
  if (sharedBoard.value?.id) {
    const uri = localePath({
      name: "boards-id",
      params: { id: sharedBoard.value?.id },
    });

    const url = new URL(
      uri,
      runtimeConfig.public.baseUrl || "http://localhost:3000"
    );

    return url.href;
  }

  return undefined;
});

const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();

const boardCover = computed(
  () =>
    sharedBoard.value?.items?.find((item) => item.images_id?.file?.id)
      ?.images_id?.file?.id
);
</script>

<template>
  <AppDialog
    v-model:is-open="isShareOpen"
    v-bind="{ title }"
    class="board-share"
    :drawer="isMobile"
  >
    <template v-if="boardCover" #drawer-bg>
      <NuxtPicture
        provider="directus"
        :src="boardCover"
        class="grow w-full h-full"
        :img-attrs="{ class: 'w-full h-full object-cover' }"
        quality="75"
        :alt="sharedBoard.value?.title"
      />
    </template>

    <template #side>
      <div
        class="board-share__side absolute inset-0 grid grid-cols-[22.75rem] justify-center items-center p-16"
        :class="[currentPalette.bg]"
      >
        <BoardGridItem :item="sharedBoard" :interactive="false" />
      </div>
    </template>

    <template #default>
      <div class="board-share__content h-full">
        <div
          class="board-share__content-wrapper h-full flex flex-col items-stretch gap-6"
        >
          <p v-if="isMobile" class="text-center">{{ sharedBoard.title }}</p>
          <p v-else>Proszę, wybierz jedną opcję z poniższych.</p>

          <PostShareSocial
            :link="link"
            :title="sharedBoard.title"
            class="grow"
          />

          <div class="flex flex-col items-stretch justify-center">
            <p class="text-center">lub</p>
          </div>

          <PostShareLink :link="link" />
        </div>
      </div>
    </template>
  </AppDialog>
</template>
